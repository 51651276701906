import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import scrollToSection from 'utils/scrollToSection';

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`;

const LinkItem = styled.li`
  position: relative;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.medium};
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin: 20px 0;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      &:after {
        transform: translateX(0) scaleX(1);
      }
    }
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 70%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.green};
    transition: 0.3s;
    transform: translateX(-50%) scaleX(0);
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 1.4rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: ${({ theme }) => theme.fontSize.m};
  }
`;

const StyledLink = styled.a`
  text-transform: uppercase;
`;

const LINKS = [
  { selector: '#o-inwestycji', label: 'o inwestycji' },
  { selector: '#browser', label: 'mieszkania' },
  { selector: '#okolica', label: 'lokalizacja' },
  { selector: '#kredyt', label: 'kredyt' },
  { selector: '#wykonczenia', label: 'wykończenia' },
  { selector: '#galeria', label: 'galeria' },
  { selector: '#kontakt', label: 'kontakt' },
  { selector: '#poradnik', label: 'poradnik' },
];

const Links = ({ close }) => {
  const location =
    typeof window !== 'undefined' ? window.location.pathname : '';

  return (
    <>
      <List>
        {LINKS.map(({ selector, label }) => (
          <LinkItem key={selector} onClick={close}>
            <StyledLink
              onClick={() => {
                if (location === '/') {
                  scrollToSection(selector);
                  return;
                }
                window.location.href = `/sokratesa-11a/${selector}`;
              }}
            >
              {label}
            </StyledLink>
          </LinkItem>
        ))}
      </List>
    </>
  );
};

Links.propTypes = {
  close: PropTypes.func,
};

Links.defaultProps = {
  close: () => null,
};

export default Links;
