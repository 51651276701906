import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;
  ${({ theme }) => theme.mq.xxxl} {
    max-width: 1350px;
  }
`;

export default Content;
